import { gql } from "@apollo/client";

const SMENA_FRAGMENT = gql`
  fragment SmenaReturn on Smena {
    _id
    info
    startTime
    endTime
    smenaType {
      _id
      name
    }
    createdAt
    disabled
  }
`;

export const SMENA = gql`
  ${SMENA_FRAGMENT}
  query SMENA($where: SmenaWhereInput!) {
    smena(where: $where) {
      ...SmenaReturn
    }
  }
`;

export const SMENAS = gql`
  ${SMENA_FRAGMENT}
  query SMENAS($where: SmenasWhereInput) {
    smenas(where: $where) {
      ...SmenaReturn
    }
  }
`;

export const CREATE_SMENA = gql`
  ${SMENA_FRAGMENT}
  mutation CREATE_SMENA($data: CreateSmenaInput!) {
    createSmena(data: $data) {
      ...SmenaReturn
    }
  }
`;

export const UPDATE_SMENA = gql`
  ${SMENA_FRAGMENT}
  mutation UPDATE_SMENA($where: SmenaWhereInput!, $data: UpdateSmenaInput!) {
    updateSmena(where: $where, data: $data) {
      ...SmenaReturn
    }
  }
`;
