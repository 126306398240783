import IMask from "imask";

export const phoneMask = IMask.createMask({
  mask: "+7 (000) 000-00-00",
});

export const priceMask = IMask.createMask({
  mask: Number,
  thousandsSeparator: " ",
});

export * from "./apollo-client";
export * from "./hooks";
