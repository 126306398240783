import { Link } from "react-router-dom";
import { Menu } from "antd";
import {
  DollarOutlined,
  FireOutlined,
  ShopOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { useUser } from "../utils";

const MenuComponent = () => {
  const { pathname } = window.location;

  const { user } = useUser();

  const moderatorMenuItems = [
    {
      icon: <ShopOutlined />,
      label: <Link to="/places">Заведения</Link>,
      key: "/places",
    },
    {
      icon: <FireOutlined />,
      label: <Link to="/events">Мероприятия</Link>,
      key: "/events",
    },
    {
      icon: <UserOutlined />,
      label: <Link to="/users">Пользователи</Link>,
      key: "/users",
    },
    {
      icon: <DollarOutlined />,
      label: <Link to="/smena-types">Смены</Link>,
      key: "/smena-types",
    },
  ];

  const placeMenuItems = [
    {
      icon: <ShopOutlined />,
      label: <Link to="/place">Заведение</Link>,
      key: "/place",
    },
    {
      icon: <FireOutlined />,
      label: <Link to="/events">Мероприятия</Link>,
      key: "/events",
    },
    {
      icon: <DollarOutlined />,
      label: <Link to="/smena-types">Смены</Link>,
      key: "/smena-types",
    },
  ];

  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[pathname]}
      items={[
        {
          icon: <UserOutlined />,
          label: <Link to="/">Профиль</Link>,
          key: "/",
        },
        ...(user.role === "admin" ? moderatorMenuItems : []),
        ...(user.role === "place" ? placeMenuItems : []),
      ]}
    />
  );
};

export default MenuComponent;
