import React from "react";
import styled from "styled-components";
import {
  Form,
  Card as AntCard,
  Input,
  Button as AntButton,
  message,
} from "antd";
import { useMutation } from "@apollo/client";

import { LOGIN_MODERATOR } from "../gqls/moderator";

const rules = {
  required: {
    required: true,
    message: "Обязательное поле",
  },
};

export const LoginPage = () => {
  const [loginModerator, { loading }] = useMutation(LOGIN_MODERATOR, {
    onCompleted: ({ loginModerator: { accessToken } }) => {
      localStorage.setItem("token", accessToken);
      window.location = "/";
    },
    onError: (e) => {
      message.error("Неправильный логин или пароль");
      console.error(e);
    },
  });

  const handleSubmitForm = ({ email, password }) => {
    loginModerator({
      variables: {
        data: {
          email,
          password,
        },
      },
    });
  };

  return (
    <Wrapper>
      <Card title="Вход в панель администратора">
        <Form onFinish={handleSubmitForm} layout="vertical" name="login">
          <Form.Item
            colon={false}
            label="Логин"
            name="email"
            rules={[rules.required]}
          >
            <Input placeholder="Введите логин..." />
          </Form.Item>
          <Form.Item label="Пароль" name="password" rules={[rules.required]}>
            <Input.Password placeholder="Введите пароль..." />
          </Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Войти
          </Button>
        </Form>
      </Card>
    </Wrapper>
  );
};

const Card = styled(AntCard)`
  width: 400px;

  @media only screen and (max-width: 420px) {
    width: 95%;
  }
`;

const Button = styled(AntButton)`
  margin-bottom: 10px;
  margin-top: 5px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

  @media only screen and (max-width: 420px) {
    justify-content: flex-start;
    padding-top: 30px;
  }
`;
