import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Form,
  Input,
  Button,
  Select,
  TimePicker,
  message,
  Upload,
  Switch,
} from "antd";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";

import Top from "../../components/Top";
import { PLACE, UPDATE_PLACE } from "../../gqls/place";
import { CATEGORIES } from "../../gqls/category";
import { phoneMask, useUser } from "../../utils";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e.length > 0 ? [e[e.length - 1]] : [];
  }
  return e?.fileList && e.fileList.length > 0
    ? [e.fileList[e.fileList.length - 1]]
    : [];
};

export const UpdatePlacePage = () => {
  const params = useParams();

  const { user } = useUser();

  const placeId = params.id;

  const [form] = Form.useForm();

  const { data: categoriesData } = useQuery(CATEGORIES, {
    fetchPolicy: "network-only",
  });

  const categories = categoriesData?.categories || [];

  const { loading: placeLoading } = useQuery(PLACE, {
    variables: { where: { _id: placeId } },
    onCompleted: (data) => {
      if (data?.place) {
        form.setFieldsValue({
          name: data.place.name,
          // category: data.place.category._id,
          categories: data.place.categories.map((item) => item._id),
          description: data.place.description,
          workStartAt: data.place.workStartAt
            ? moment(data.place.workStartAt)
            : undefined,
          workEndAt: data.place.workEndAt
            ? moment(data.place.workEndAt)
            : undefined,
          published: data.place.published,
          logo: [
            {
              status: "done",
              url: `/uploads/${data.place.image}`,
              name: data.place.image,
              response: { filename: data.place.image },
            },
          ],
          contactPhone: data.place.contactPhone
            ? phoneMask.resolve(data.place.contactPhone)
            : undefined,
          weekend: data.place.weekend,
        });
      }
    },
  });

  const navigate = useNavigate();

  const [updatePlace, { loading }] = useMutation(UPDATE_PLACE, {
    onCompleted: () => {
      message.success("Заведение успешно изменено");
      navigate("/places/" + placeId);
    },
    onError: (err) => {
      console.error(err);
      message.error("Произошла ошибка, повторите еще раз");
    },
  });

  const handleFinish = (values) => {
    if (values?.workStartAt) {
      values.workStartAt = values.workStartAt.toISOString();
    }
    if (values?.workEndAt) {
      values.workEndAt = values.workEndAt.toISOString();
    }
    const logo = values?.logo?.[0]?.response?.filename;
    if (!logo) {
      message.error("Выберите файл для загрузки");
      return;
    }
    if (values.contactPhone) {
      values.contactPhone = values.contactPhone
        .replace(/ /g, "")
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        .replace(/-/g, "");
    }
    if (values.contactPhone && values.contactPhone.length !== 12) {
      message.error("Введите правильный номер телефона");
      return;
    }
    updatePlace({
      variables: {
        data: {
          name: values.name,
          description: values.description,
          workStartAt: values.workStartAt,
          workEndAt: values.workEndAt,
          // categoryId: values.category,
          categoriesIds: values.categories,
          image: logo,
          published: values.published,
          contactPhone: values.contactPhone,
          weekend: values.weekend,
        },
        where: { _id: placeId },
      },
    });
  };

  return (
    <>
      <Top title="Изменить заведение" />
      <Container>
        <Form
          form={form}
          name="update-place"
          onFinish={handleFinish}
          layout="vertical"
        >
          <Form.Item
            name="logo"
            label="Логотип"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true }]}
            extra="Старайтесь загружать файлы не больше 500 КБ"
          >
            <Upload
              name="file"
              action="/api/upload"
              listType="picture"
              accept="image/*"
              headers={{ authorization: localStorage.getItem("token") }}
              disabled={placeLoading}
            >
              <Button icon={<UploadOutlined />}>
                Нажмите, чтобы загрузить
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="Название заведения"
            name="name"
            rules={[{ required: true }]}
          >
            <Input disabled={placeLoading} />
          </Form.Item>
          {/* <Form.Item
            label="Категория"
            name="category"
            rules={[{ required: true }]}
          >
            <Select disabled={placeLoading}>
              {categories.map((item) => (
                <Select.Option key={item._id} value={item._id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item> */}
          <Form.Item
            label="Категории"
            name="categories"
            rules={[{ required: true }]}
          >
            <Select disabled={placeLoading} mode="multiple">
              {categories.map((item) => (
                <Select.Option key={item._id} value={item._id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Выходные" name="weekend">
            <Input />
          </Form.Item>
          <Form.Item label="Время начала работы" name="workStartAt">
            <TimePicker
              disabled={placeLoading}
              style={{ width: "100%" }}
              format="HH:mm"
            />
          </Form.Item>
          <Form.Item label="Время конца работы" name="workEndAt">
            <TimePicker
              disabled={placeLoading}
              style={{ width: "100%" }}
              format="HH:mm"
            />
          </Form.Item>
          <Form.Item
            label="Описание"
            name="description"
            rules={[{ required: true }]}
          >
            <Input.TextArea disabled={placeLoading} rows={4} />
          </Form.Item>
          {user.role === "admin" && (
            <Form.Item
              name="published"
              label="Опубликован"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          )}
          <Form.Item
            getValueFromEvent={(e) => phoneMask.resolve(e.target.value)}
            label="Контактный номер телефона"
            name="contactPhone"
          >
            <Input disabled={placeLoading} />
          </Form.Item>
          <Button
            loading={loading || placeLoading}
            type="primary"
            htmlType="submit"
          >
            Изменить
          </Button>
        </Form>
      </Container>
    </>
  );
};

const Container = styled.div`
  max-width: 500px;
`;
