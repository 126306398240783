import React from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Button, Empty, Spin, Tag } from "antd";
import moment from "moment";

import Top from "../../components/Top";
import { PLACE } from "../../gqls/place";
import LabeledValue from "../../components/LabeledValue";
import { phoneMask, useUser } from "../../utils";
import { Smenas } from "../smenas/smenas";
import { Checkers } from "./checkers";

export const PlacePage = () => {
  const params = useParams();

  const { user } = useUser();

  const placeId = user.role === "place" ? user?.place?._id : params?.id;

  const { data: placeData, loading } = useQuery(PLACE, {
    variables: { where: { _id: placeId } },
  });

  const place = placeData?.place;

  return (
    <>
      <Top
        title="Заведение"
        action={
          <Link to={`/places/${placeId}/update`}>
            <Button type="primary" ghost>
              Изменить
            </Button>
          </Link>
        }
      />
      {loading ? (
        <Spin />
      ) : !place ? (
        <Empty description="Не найдено" />
      ) : (
        <>
          {place.image && (
            <Logo src={`/uploads/${place.image}`} alt={place.image} />
          )}
          <LabeledValue label="Название" value={place.name} />
          {place.weekend && (
            <LabeledValue label="Выходные" value={place.weekend} />
          )}
          {place.workStartAt && place.workEndAt && (
            <LabeledValue
              label="Время работы"
              value={`${moment(place.workStartAt).format("HH:mm")} - ${moment(
                place.workEndAt
              ).format("HH:mm")}`}
            />
          )}
          <LabeledValue
            label="Категории"
            value={
              place.categories?.length
                ? place.categories.map((item) => item.name).join(", ")
                : place.category.name
            }
          />
          <LabeledValue
            label="Дата регистрации"
            value={moment(place.createdAt).format("DD.MM.YYYY")}
          />
          <LabeledValue
            label="Статус"
            value={
              place.published ? (
                <Tag color="green">Опубликован</Tag>
              ) : (
                <Tag color="red">Не опубликован</Tag>
              )
            }
          />
          <LabeledValue
            label="Контактный номер телефона"
            value={
              place.contactPhone
                ? phoneMask.resolve(place.contactPhone)
                : undefined
            }
          />
          <LabeledValue label="Описание" value={place.description} />

          <Checkers placeId={place._id} />

          <Smenas placeId={place._id} />
        </>
      )}
    </>
  );
};

const Logo = styled.img`
  height: 150px;
  border: 1px solid #d8d8d8;
  margin-bottom: 15px;
`;
