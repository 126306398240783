import { gql } from "@apollo/client";

const MODERATOR_FRAGMENT = gql`
  fragment ModeratorReturn on Moderator {
    _id
    name
    email
    role
    createdAt
    place {
      _id
    }
  }
`;

export const MODERATOR = gql`
  ${MODERATOR_FRAGMENT}
  query MODERATOR {
    moderator {
      ...ModeratorReturn
    }
  }
`;

export const LOGIN_MODERATOR = gql`
  ${MODERATOR_FRAGMENT}
  mutation LOGIN_MODERATOR($data: LoginModeratorInput!) {
    loginModerator(data: $data) {
      accessToken
      moderator {
        ...ModeratorReturn
      }
    }
  }
`;

export const CHANGE_PASSWORD_MODERATOR = gql`
  ${MODERATOR_FRAGMENT}
  mutation CHANGE_PASSWORD_MODERATOR($data: ChangePasswordModerator!) {
    changePasswordModerator(data: $data) {
      ...ModeratorReturn
    }
  }
`;
