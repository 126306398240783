import React from "react";
import { Button, Form as AntForm, Input, message } from "antd";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import Top from "../components/Top";
import { CHANGE_PASSWORD_MODERATOR } from "../gqls/moderator";

const Form = styled(AntForm)`
  max-width: 500px;
`;

export const ChangePasswordPage = () => {
  const navigate = useNavigate();

  const [changePasswordModerator, { loading }] = useMutation(
    CHANGE_PASSWORD_MODERATOR,
    {
      onCompleted: () => {
        message.success("Пароль успешно изменен");
        navigate("/");
      },
      onError: (err) => {
        console.error(err);
        if (err.message === "password-error") {
          message.error("Неправильный текущий пароль");
        } else {
          message.error("Произошла ошибка, повторите еще раз");
        }
      },
    }
  );

  const handleFinish = (values) => {
    if (values.repeatPassword !== values.newPassword) {
      message.error("Пароли не совпадают");
      return;
    }
    changePasswordModerator({
      variables: {
        data: {
          newPassword: values.newPassword,
          currentPassword: values.currentPassword,
        },
      },
    });
  };

  return (
    <>
      <Top title="Изменить пароль" />
      <Form name="change-password" layout="vertical" onFinish={handleFinish}>
        <Form.Item
          label="Текущий пароль"
          name="currentPassword"
          rules={[{ required: true }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Новый пароль"
          name="newPassword"
          rules={[{ required: true }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Повторите новый пароль"
          name="repeatPassword"
          rules={[{ required: true }]}
        >
          <Input.Password />
        </Form.Item>
        <Button loading={loading} type="primary" htmlType="submit">
          Изменить
        </Button>
      </Form>
    </>
  );
};
