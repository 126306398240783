import React from "react";
import { useMutation } from "@apollo/client";
import { Alert, Button, Form, Input, Modal, message } from "antd";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import isBetween from "dayjs/plugin/isBetween";
import dayjs from "dayjs";

import { CREATE_SMENA } from "../../gqls/smena";

dayjs.extend(LocalizedFormat);
dayjs.extend(isBetween);

export const CreateSmenaModal = ({
  createVisible,
  onClose,
  refetch,
  smenaTypeId,
  creatingDate,
}) => {
  const startTime = dayjs(creatingDate)
    .set("hours", 7)
    .set("minutes", 0)
    .set("seconds", 0)
    .set("milliseconds", 0);
  const endTime = startTime.add(1, "days");

  const [createSmena, { loading: creating }] = useMutation(CREATE_SMENA, {
    onCompleted: () => {
      refetch();
      onClose();
      message.success("Смена добавлена");
    },
    onError: (err) => {
      console.error(err);
      if (err.message === "already-exist") {
        message.error("Смена с этим днем недели уже существует");
      } else {
        message.error("Произошла ошибка, повторите еще раз");
      }
    },
  });

  const handleFinishCreate = (values) => {
    createSmena({
      variables: {
        data: {
          info: values.info,
          startTime: startTime.toISOString(),
          endTime: endTime.toISOString(),
          smenaTypeId,
        },
      },
    });
  };

  return (
    <Modal
      title={
        creatingDate
          ? `Добавить смену на ${dayjs(creatingDate).locale("ru").format("ll")}`
          : ""
      }
      open={createVisible}
      onCancel={() => onClose()}
      footer={[
        <Button key="cancel" onClick={() => onClose()}>
          Отмена
        </Button>,
        <Button
          key="update"
          type="primary"
          htmlType="submit"
          form="create-smena"
          loading={creating}
        >
          Добавить
        </Button>,
      ]}
      destroyOnClose
    >
      <Form onFinish={handleFinishCreate} layout="vertical" name="create-smena">
        <SmenaForm />
        {dayjs().isBetween(startTime, endTime) ? (
          <Alert
            type="warning"
            message="Если добавите смену на сегоднящнее число, его уже нельзя будет изменить"
            style={{ marginTop: -12 }}
          />
        ) : null}
      </Form>
    </Modal>
  );
};

export const SmenaForm = ({ disable = false }) => {
  return (
    <>
      <Form.Item
        label="Информация (акции, скидки)"
        name="info"
        rules={[{ required: true }]}
      >
        <Input.TextArea disabled={disable} rows={4} />
      </Form.Item>
    </>
  );
};
