import { gql } from "@apollo/client";

export const USER_FRAGMENT = gql`
  fragment UserReturn on User {
    _id
    name
    username
    phone
    role
    createdAt
    avatar
  }
`;

export const USERS = gql`
  ${USER_FRAGMENT}

  query USERS($data: UsersInput) {
    users(data: $data) {
      ...UserReturn
    }

    usersCount(data: $data)
  }
`;

export const CREATE_PLACE_USER = gql`
  ${USER_FRAGMENT}

  mutation CREATE_PLACE_USER($data: CreatePlaceUser!) {
    createPlaceUser(data: $data) {
      ...UserReturn
    }
  }
`;
