import React, { useState } from "react";
import { Calendar, Col, Row, Select, Spin } from "antd";
import { useQuery } from "@apollo/client";
import ruDayJs from "dayjs/locale/ru";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

import Top from "../../components/Top";
import { SMENAS } from "../../gqls/smena";
import { CreateSmenaModal } from "./create-smena";
import { useUser } from "../../utils";
import styled from "styled-components";
import { UpdateSmena } from "./update-smena";

dayjs.extend(isBetween);

export const Smenas = ({ smenaTypeId, placeId }) => {
  const { data, loading, refetch } = useQuery(SMENAS, {
    variables: { where: { smenaTypeId, placeId } },
    fetchPolicy: "network-only",
  });

  const { user } = useUser();

  const [createVisible, setCreateVisible] = useState(false);
  const [creatingDate, setCreatingDate] = useState(undefined);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [updatingSmena, setUpdatingSmena] = useState(undefined);

  const smenas = data?.smenas || [];

  const dateCellRender = (value) => {
    const date = value;
    const startDate = date.startOf("day");
    const endDate = date.endOf("day");
    const smenaOnDates = smenas.filter((smena) => {
      return dayjs(smena.startTime).isBetween(startDate, endDate);
    });
    if (!smenaOnDates.length) {
      return null;
    }
    return (
      <>
        {smenaOnDates.map((smenaOnDate) => (
          <Smena
            onClick={() => {
              setUpdatingSmena(smenaOnDate);
              setUpdateVisible(true);
              setCreatingDate(date);
            }}
            key={smenaOnDate._id}
            type={smenaOnDate?.smenaType?.name}
          >
            <div className="info">{smenaOnDate.info}</div>
          </Smena>
        ))}
      </>
    );
  };

  const cellRender = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    if (info.type === "month") return null;
    return info.originNode;
  };

  const handleSelect = (date) => {
    if (user.role === "place") {
      const startDate = date.startOf("day");
      const endDate = date.endOf("day");
      const smenaOnDate = smenas.find((smena) => {
        return dayjs(smena.startTime).isBetween(startDate, endDate);
      });
      if (!smenaOnDate) {
        setCreatingDate(date);
        setCreateVisible(true);
      }
    }
  };

  return (
    <>
      <Top title="Смены" style={{ marginTop: 30 }} />
      {loading ? (
        <Spin />
      ) : (
        <Calendar
          validRange={[dayjs(), dayjs().add(7, "days")]}
          headerRender={Header}
          cellRender={cellRender}
          onSelect={handleSelect}
        />
      )}
      <CreateSmenaModal
        createVisible={createVisible}
        onClose={() => {
          setCreateVisible(false);
          setCreatingDate(undefined);
        }}
        refetch={refetch}
        smenaTypeId={smenaTypeId}
        creatingDate={creatingDate}
      />
      <UpdateSmena
        refetch={refetch}
        onClose={() => {
          setUpdateVisible(false);
          setUpdatingSmena(undefined);
          setCreatingDate(undefined);
        }}
        smena={updatingSmena}
        updateVisible={updateVisible}
        updatingDate={creatingDate}
      />
    </>
  );
};

const Header = ({ value, type, onChange, onTypeChange }) => {
  const start = 0;
  const end = 12;
  const monthOptions = [];
  let current = value.clone();
  const localeData = ruDayJs;
  const months = [];
  for (let i = 0; i < 12; i++) {
    current = current.month(i);
    months.push(localeData.monthsShort(current));
  }
  for (let i = start; i < end; i++) {
    monthOptions.push(
      <Select.Option key={i} value={i} className="month-item">
        {months[i]}
      </Select.Option>
    );
  }
  const year = value.year();
  const month = value.month();
  const options = [];
  for (let i = year - 10; i < year + 10; i += 1) {
    options.push(
      <Select.Option key={i} value={i} className="year-item">
        {i}
      </Select.Option>
    );
  }
  return (
    <div style={{ padding: 0, marginBottom: 15 }}>
      <Row gutter={8}>
        <Col>
          <Select
            size="small"
            dropdownMatchSelectWidth={false}
            className="my-year-select"
            value={year}
            onChange={(newYear) => {
              const now = value.clone().year(newYear);
              onChange(now);
            }}
          >
            {options}
          </Select>
        </Col>
        <Col>
          <Select
            size="small"
            dropdownMatchSelectWidth={false}
            value={month}
            onChange={(newMonth) => {
              const now = value.clone().month(newMonth);
              onChange(now);
            }}
          >
            {monthOptions}
          </Select>
        </Col>
      </Row>
    </div>
  );
};

const Smena = styled.div`
  pointer-events: auto;
  border: 1px solid silver;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 6px;
  cursor: pointer;
  transition: 0.3s border;

  &:hover {
    ${(props) =>
      props.type === "standart" ? "border: 1px solid #0F583D;" : ""}

    ${(props) => (props.type === "vip" ? "border: 1px solid #B8860B;" : "")}

    ${(props) =>
      props.type === "platinum" ? "border: 1px solid #CCCCCC;" : ""}
  }

  ${(props) =>
    props.type === "standart" ? "background-color: rgba(15, 88, 61, 0.1);" : ""}

  ${(props) =>
    props.type === "vip" ? "background-color: rgba(184, 134, 11, 0.3);" : ""}

  ${(props) =>
    props.type === "platinum"
      ? "background-color: rgba(204, 204, 204, 1);"
      : ""}

  .info {
    font-size: 8px;
    white-space: pre-line;
  }
`;
