import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Form, Input, Button, Select, TimePicker, message, Upload } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import Top from "../../components/Top";
import { CREATE_PLACE } from "../../gqls/place";
import { CATEGORIES } from "../../gqls/category";
import { UploadOutlined } from "@ant-design/icons";
import { useUser } from "../../utils";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e.length > 0 ? [e[e.length - 1]] : [];
  }
  return e?.fileList && e.fileList.length > 0
    ? [e.fileList[e.fileList.length - 1]]
    : [];
};

export const CreatePlacePage = () => {
  const { data: categoriesData } = useQuery(CATEGORIES, {
    fetchPolicy: "network-only",
  });

  const { user } = useUser();

  const navigate = useNavigate();

  const [createPlace, { loading }] = useMutation(CREATE_PLACE, {
    onCompleted: () => {
      message.success("Заведение успешно добавлено");
      navigate("/places");
    },
    onError: (err) => {
      console.error(err);
      if (err.message === "email-error") {
        message.error("Ошибка отправки почты");
      } else if (err.message === "moderator-exist") {
        message.error("Модератор с такой почтой уже существует");
      } else {
        message.error("Произошла ошибка, повторите еще раз");
      }
    },
  });

  const categories = categoriesData?.categories || [];

  const handleFinish = (values) => {
    if (values?.workStartAt) {
      values.workStartAt = values.workStartAt.toISOString();
    }
    if (values?.workEndAt) {
      values.workEndAt = values.workEndAt.toISOString();
    }
    const logo = values?.logo?.[0]?.response?.filename;
    if (!logo) {
      message.error("Выберите файл для загрузки");
      return;
    }
    createPlace({
      variables: {
        data: {
          name: values.name,
          description: values.description,
          workStartAt: values.workStartAt,
          workEndAt: values.workEndAt,
          // categoryId: values.category,
          categoriesIds: values.categories,
          image: logo,
          moderatorEmail: values.moderatorEmail,
        },
      },
    });
  };

  if (user?.role !== "admin") return null;

  return (
    <>
      <Top title="Добавить заведение" />
      <Container>
        <Form name="create-place" onFinish={handleFinish} layout="vertical">
          <Form.Item
            name="logo"
            label="Логотип"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true }]}
            extra="Старайтесь загружать файлы не больше 500 КБ"
          >
            <Upload
              name="file"
              action="/api/upload"
              listType="picture"
              accept="image/*"
              headers={{ authorization: localStorage.getItem("token") }}
            >
              <Button icon={<UploadOutlined />}>
                Нажмите, чтобы загрузить
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="Название заведения"
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item
            label="Категория"
            name="category"
            rules={[{ required: true }]}
          >
            <Select>
              {categories.map((item) => (
                <Select.Option key={item._id} value={item._id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item> */}
          <Form.Item
            label="Категории"
            name="categories"
            rules={[{ required: true }]}
          >
            <Select mode="multiple">
              {categories.map((item) => (
                <Select.Option key={item._id} value={item._id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Время начала работы" name="workStartAt">
            <TimePicker style={{ width: "100%" }} format="HH:mm" />
          </Form.Item>
          <Form.Item label="Время конца работы" name="workEndAt">
            <TimePicker style={{ width: "100%" }} format="HH:mm" />
          </Form.Item>
          <Form.Item
            label="Описание"
            name="description"
            rules={[{ required: true }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item
            label="Email модератора"
            name="moderatorEmail"
            rules={[{ required: true }, { type: "email" }]}
            extra="Будет отправлен логин и пароль на эту почту"
          >
            <Input />
          </Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Добавить
          </Button>
        </Form>
      </Container>
    </>
  );
};

const Container = styled.div`
  max-width: 500px;
`;
