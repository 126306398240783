import { gql } from "@apollo/client";

const EVENT_FRAGMENT = gql`
  fragment EventReturn on Event {
    _id
    name
    images
    description
    published
    createdAt
    place {
      _id
      name
    }
  }
`;

export const EVENT = gql`
  ${EVENT_FRAGMENT}
  query EVENT($where: EventWhereInput!) {
    event(where: $where) {
      ...EventReturn
    }
  }
`;

export const EVENTS = gql`
  ${EVENT_FRAGMENT}
  query EVENTS($data: EventsInput) {
    events(data: $data) {
      ...EventReturn
    }

    eventsCount(data: $data)
  }
`;

export const CREATE_EVENT = gql`
  ${EVENT_FRAGMENT}
  mutation CREATE_EVENT($data: CreateEventInput!) {
    createEvent(data: $data) {
      ...EventReturn
    }
  }
`;

export const UPDATE_EVENT = gql`
  ${EVENT_FRAGMENT}
  mutation UPDATE_EVENT($data: UpdateEventInput!, $where: EventWhereInput!) {
    updateEvent(data: $data, where: $where) {
      ...EventReturn
    }
  }
`;

export const DELETE_EVENT = gql`
  ${EVENT_FRAGMENT}
  mutation DELETE_EVENT($where: EventWhereInput!) {
    deleteEvent(where: $where) {
      ...EventReturn
    }
  }
`;
