import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Table, Button } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Top from "../../components/Top";
import { PLACES } from "../../gqls/place";
import { useUser } from "../../utils";

const LIMIT = 20;

export const PlacesPage = () => {
  const { user } = useUser();

  const [page, setPage] = useState(1);

  const { data, loading } = useQuery(PLACES, {
    variables: {
      data: { skip: (page - 1) * LIMIT, limit: LIMIT },
    },
    fetchPolicy: "network-only",
  });

  const places = data?.places || [];
  const placesCount = data?.placesCount || 0;

  const handleChangeTable = (pagination) => {
    setPage(pagination.current);
  };

  if (user?.role !== "admin") return null;

  return (
    <>
      <Top
        title="Заведения"
        action={
          <Link to="/places/create">
            <Button type="primary" ghost>
              Добавить
            </Button>
          </Link>
        }
      />
      <Table
        loading={loading}
        dataSource={places}
        rowKey={(row) => row._id}
        pagination={{
          pageSize: LIMIT,
          current: page,
          total: placesCount,
        }}
        onChange={handleChangeTable}
        columns={[
          {
            title: "",
            key: "image",
            render: (row) => (
              <Logo src={`/uploads/${row.image}`} alt={row.image} />
            ),
          },
          {
            title: "Название",
            key: "name",
            dataIndex: "name",
            render: (name, row) => (
              <Link to={`/places/${row._id}`}>{name}</Link>
            ),
          },
          {
            title: "Категории",
            render: (row) =>
              row.categories?.length
                ? row.categories.map((item) => item.name).join(", ")
                : row.category?.name,
          },
          {
            title: "Дата создания",
            render: (row) => moment(row.createdAt).format("DD.MM.YYYY HH:mm"),
          },
        ]}
      />
    </>
  );
};

const Logo = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 25px;
  object-fit: cover;
`;
