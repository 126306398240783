import { useQuery } from "@apollo/client";

import { MODERATOR } from "../gqls/moderator";

export const useUser = () => {
  const { data, loading, error } = useQuery(MODERATOR);

  const user = data ? data.moderator : null;

  return {
    loading,
    error,
    user,
  };
};
