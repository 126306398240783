import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import Top from "../../components/Top";
import { SMENA_TYPE, UPDATE_SMENA_TYPE } from "../../gqls/smena-type";
import {
  Button,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Spin,
  message,
} from "antd";
import LabeledValue from "../../components/LabeledValue";
import { renderSmenaTypeName } from "./index";
import { priceMask, useUser } from "../../utils";
import { Smenas } from "./smenas";

export const SmenaTypePage = () => {
  const params = useParams();

  const [visibleUpdate, setVisibleUpdate] = useState(false);

  const smenaTypeId = params?.id;

  const { user } = useUser();

  const { data, loading, refetch } = useQuery(SMENA_TYPE, {
    fetchPolicy: "network-only",
    variables: { where: { _id: smenaTypeId } },
  });

  const [updateSmenaType, { loading: updating }] = useMutation(
    UPDATE_SMENA_TYPE,
    {
      onCompleted: () => {
        refetch();
        setVisibleUpdate(false);
        message.success("Тип смены изменена");
      },
      onError: (err) => {
        console.error(err);
        message.error("Произошла ошибка, повторите еще раз");
      },
    }
  );

  const closeVisibleUpdate = () => setVisibleUpdate(false);

  const handleFinishUpdate = (values) => {
    updateSmenaType({
      variables: {
        where: { _id: smenaTypeId },
        data: { price: values.price, description: values.description },
      },
    });
  };

  const smenaType = data?.smenaType;

  return (
    <>
      <Top title="Тип смены" />
      {loading ? (
        <Spin />
      ) : !smenaType ? (
        <Empty />
      ) : (
        <>
          <LabeledValue
            label="Тип"
            value={renderSmenaTypeName(smenaType.name)}
          />
          <LabeledValue
            label="Цена"
            value={
              smenaType.price
                ? `${priceMask.resolve(String(smenaType.price))} ₽`
                : "-"
            }
          />
          <LabeledValue label="Описание" value={smenaType.description} />
          {user.role === "admin" ? (
            <Actions>
              <Button
                onClick={() => setVisibleUpdate(true)}
                type="primary"
                ghost
              >
                Изменить
              </Button>
            </Actions>
          ) : null}
          {user?.role === "place" && user?.place?._id ? (
            <Smenas smenaTypeId={smenaTypeId} placeId={user.place._id} />
          ) : null}
          <Modal
            title="Изменить тип смены"
            open={visibleUpdate}
            onCancel={closeVisibleUpdate}
            footer={[
              <Button key="cancel" onClick={closeVisibleUpdate}>
                Отмена
              </Button>,
              <Button
                key="update"
                type="primary"
                htmlType="submit"
                form="update-smena-type"
                loading={updating}
              >
                Изменить
              </Button>,
            ]}
            destroyOnClose
          >
            <Form
              onFinish={handleFinishUpdate}
              layout="vertical"
              name="update-smena-type"
              initialValues={{
                price: smenaType.price,
                description: smenaType.description,
              }}
            >
              <Form.Item name="price" label="Цена" rules={[{ required: true }]}>
                <InputNumber addonBefore="₽" />
              </Form.Item>
              <Form.Item name="description" label="Описание">
                <Input.TextArea rows={4} />
              </Form.Item>
            </Form>
          </Modal>
        </>
      )}
    </>
  );
};

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`;
