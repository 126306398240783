import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Button, Input, Table } from "antd";
import moment from "moment";
import styled from "styled-components";

import Top from "../../components/Top";
import { useUser } from "../../utils";
import { USERS } from "../../gqls/user";

const LIMIT = 20;

export const UsersPage = () => {
  const { user } = useUser();

  const [page, setPage] = useState(1);
  const [filterUsername, setFilterUsername] = useState("");
  const [filterName, setFilterName] = useState("");
  const [filterPhone, setFilterPhone] = useState("");
  const [searchUsername, setSearchUsername] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchPhone, setSearchPhone] = useState("");

  const { data, loading } = useQuery(USERS, {
    variables: {
      data: {
        skip: (page - 1) * LIMIT,
        limit: LIMIT,
        phone: searchPhone || undefined,
        name: searchName || undefined,
        search: searchUsername || undefined,
      },
    },
    fetchPolicy: "network-only",
  });

  const handleChangeTable = (pagination) => {
    setPage(pagination.current);
  };

  const handleSearch = () => {
    setSearchUsername(filterUsername || "");
    setSearchName(filterName || "");
    setSearchPhone(filterPhone || "");
    setPage(1);
  };

  if (user?.role !== "admin") return null;

  const users = data?.users || [];
  const usersCount = data?.usersCount || 0;

  return (
    <>
      <Top title="Пользователи" />
      <Filter>
        <Input
          value={filterUsername}
          onChange={(e) => setFilterUsername(e.target.value)}
          placeholder="Ник"
          className="filter"
        />
        <Input
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
          placeholder="Имя"
          className="filter"
        />
        <Input
          value={filterPhone}
          onChange={(e) => setFilterPhone(e.target.value)}
          placeholder="Номер"
          className="filter"
        />
        <Button onClick={handleSearch} type="primary" ghost>
          Поиск
        </Button>
      </Filter>
      <Table
        rowKey={(row) => row._id}
        dataSource={users}
        columns={[
          {
            title: "Ник",
            dataIndex: "username",
            key: "username",
          },
          {
            title: "Имя",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Номер",
            dataIndex: "phone",
            key: "phone",
          },
          {
            title: "Дата регистрации",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (createdAt) => moment(createdAt).format("DD.MM.YYYY HH:mm"),
          },
        ]}
        loading={loading}
        pagination={{
          pageSize: LIMIT,
          current: page,
          total: usersCount,
        }}
        onChange={handleChangeTable}
      />
    </>
  );
};

const Filter = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 5px;

  .filter {
    margin-right: 15px;
    margin-bottom: 15px;
    width: 180px;
  }
`;
