import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { ConfigProvider } from "antd";
import locale from "antd/locale/ru_RU";
import "dayjs/locale/ru";
import "moment/locale/ru";

import GlobalStyles from "./components/GlobalStyles";
import { apolloClient } from "./utils";
import { ProfilePage } from "./pages/profile";
import { Layout } from "./components/Layout";
import { LoginPage } from "./pages/login";
import { ChangePasswordPage } from "./pages/change-password";
import { PlacesPage } from "./pages/places";
import { CreatePlacePage } from "./pages/places/create";
import { PlacePage } from "./pages/places/place";
import { UpdatePlacePage } from "./pages/places/update";
import { EventsPage } from "./pages/events";
import { CreateEventPage } from "./pages/events/create";
import { EventPage } from "./pages/events/event";
import { UpdateEventPage } from "./pages/events/update";
import { UsersPage } from "./pages/users";
import { SmenaTypesPage } from "./pages/smenas";
import { SmenaTypePage } from "./pages/smenas/smena-type";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <ProfilePage />
      </Layout>
    ),
  },
  {
    path: "/profile/change-password",
    element: (
      <Layout>
        <ChangePasswordPage />
      </Layout>
    ),
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/places",
    element: (
      <Layout>
        <PlacesPage />
      </Layout>
    ),
  },
  {
    path: "/places/create",
    element: (
      <Layout>
        <CreatePlacePage />
      </Layout>
    ),
  },
  {
    path: "/places/:id",
    element: (
      <Layout>
        <PlacePage />
      </Layout>
    ),
  },
  {
    path: "/places/:id/update",
    element: (
      <Layout>
        <UpdatePlacePage />
      </Layout>
    ),
  },
  {
    path: "/place",
    element: (
      <Layout>
        <PlacePage />
      </Layout>
    ),
  },
  {
    path: "/events",
    element: (
      <Layout>
        <EventsPage />
      </Layout>
    ),
  },
  {
    path: "/events/create",
    element: (
      <Layout>
        <CreateEventPage />
      </Layout>
    ),
  },
  {
    path: "/events/:id",
    element: (
      <Layout>
        <EventPage />
      </Layout>
    ),
  },
  {
    path: "/events/:id/update",
    element: (
      <Layout>
        <UpdateEventPage />
      </Layout>
    ),
  },
  {
    path: "/users",
    element: (
      <Layout>
        <UsersPage />
      </Layout>
    ),
  },
  {
    path: "/smena-types",
    element: (
      <Layout>
        <SmenaTypesPage />
      </Layout>
    ),
  },
  {
    path: "/smena-types/:id",
    element: (
      <Layout>
        <SmenaTypePage />
      </Layout>
    ),
  },
]);

const App = () => {
  return (
    <ConfigProvider locale={locale}>
      <ApolloProvider client={apolloClient}>
        <RouterProvider router={router} />
        <GlobalStyles />
      </ApolloProvider>
    </ConfigProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);
