import { gql } from "@apollo/client";

const SMENA_TYPE_FRAGMENT = gql`
  fragment SmenaTypeReturn on SmenaType {
    _id
    name
    price
    image
    description
    createdAt
  }
`;

export const SMENA_TYPES = gql`
  ${SMENA_TYPE_FRAGMENT}

  query SMENA_TYPES {
    smenaTypes {
      ...SmenaTypeReturn
    }
  }
`;

export const SMENA_TYPE = gql`
  ${SMENA_TYPE_FRAGMENT}

  query SMENA_TYPE($where: SmenaTypeWhereInput!) {
    smenaType(where: $where) {
      ...SmenaTypeReturn
    }
  }
`;

export const UPDATE_SMENA_TYPE = gql`
  ${SMENA_TYPE_FRAGMENT}

  mutation UPDATE_SMENA_TYPE(
    $where: SmenaTypeWhereInput!
    $data: UpdateSmenaTypeInput!
  ) {
    updateSmenaType(where: $where, data: $data) {
      ...SmenaTypeReturn
    }
  }
`;
