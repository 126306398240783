import React, { useState } from "react";
import { Button, Form, Input, Modal, Table, message } from "antd";
import { useMutation, useQuery } from "@apollo/client";

import Top from "../../components/Top";
import { CREATE_PLACE_USER, USERS } from "../../gqls/user";

export const Checkers = ({ placeId }) => {
  const { data, loading, refetch } = useQuery(USERS, {
    variables: { data: { placeId } },
    fetchPolicy: "network-only",
  });

  const [createPlaceUser, { loading: creating }] = useMutation(
    CREATE_PLACE_USER,
    {
      onError: (err) => {
        console.error(err);
        message.error("Произошла ошибка, повторите еще раз");
      },
      onCompleted: () => {
        refetch();
        message.success("Проверяющий добавлен");
        setCreatePlaceVisible(false);
      },
    }
  );

  const [createPlaceVisible, setCreatePlaceVisible] = useState(false);

  const handleFinish = (values) => {
    if (values.phone.length !== 12) {
      message.warning("Введите правильный номер телефона");
      return;
    }
    if (values.phone[0] !== "+" || values.phone[1] !== "7") {
      message.warning("Введите правильный формат номер телефона");
      return;
    }
    createPlaceUser({
      variables: { data: { placeId, phone: values.phone, name: values.name } },
    });
  };

  const checkers = data?.users || [];

  return (
    <>
      <Top
        title="Проверяющие"
        style={{ marginTop: 30 }}
        action={
          <Button
            onClick={() => setCreatePlaceVisible(true)}
            type="primary"
            ghost
          >
            Добавить
          </Button>
        }
      />
      <Table
        rowKey={(row) => row._id}
        dataSource={checkers}
        loading={loading}
        columns={[
          {
            title: "Номер телефона",
            dataIndex: "phone",
            key: "phone",
          },
          {
            title: "Имя",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Ник",
            dataIndex: "username",
            key: "username",
          },
        ]}
      />
      <Modal
        title="Добавить проверяющего"
        open={createPlaceVisible}
        onCancel={() => setCreatePlaceVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setCreatePlaceVisible(false)}>
            Отмена
          </Button>,
          <Button
            key="submit"
            htmlType="submit"
            form="create-checker"
            type="primary"
            loading={creating}
          >
            Добавить
          </Button>,
        ]}
      >
        <Form onFinish={handleFinish} layout="vertical" name="create-checker">
          <Form.Item name="name" label="Имя" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Номер телефона"
            rules={[{ required: true }]}
          >
            <Input placeholder="+79241112233" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
