import React from "react";
import { useQuery } from "@apollo/client";
import { Table } from "antd";
import { Link } from "react-router-dom";

import Top from "../../components/Top";
import { SMENA_TYPES } from "../../gqls/smena-type";
import { priceMask } from "../../utils";

export const SmenaTypesPage = () => {
  const { data, loading } = useQuery(SMENA_TYPES, {
    fetchPolicy: "network-only",
  });

  const smenaTypes = data?.smenaTypes || [];

  return (
    <>
      <Top title="Типы смен" />
      <Table
        loading={loading}
        rowKey={(row) => row._id}
        dataSource={smenaTypes}
        columns={[
          {
            title: "Тип",
            dataIndex: "name",
            key: "name",
            render: (name, smena) => (
              <Link to={`/smena-types/${smena._id}`}>
                {renderSmenaTypeName(name)}
              </Link>
            ),
          },
          {
            title: "Цена",
            dataIndex: "price",
            key: "price",
            render: (price) =>
              price ? `${priceMask.resolve(String(price))} ₽` : "-",
          },
          {
            title: "Описание",
            dataIndex: "description",
            key: "description",
          },
        ]}
      />
    </>
  );
};

export const renderSmenaTypeName = (name) => {
  if (name === "standart") {
    return "Standart";
  }
  if (name === "vip") {
    return "VIP";
  }
  if (name === "platinum") {
    return "Platinum";
  }
  return "";
};
