import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Empty, Popconfirm, Spin, Tag, message } from "antd";
import styled from "styled-components";
import moment from "moment";

import Top from "../../components/Top";
import { DELETE_EVENT, EVENT } from "../../gqls/event";
import LabeledValue from "../../components/LabeledValue";

export const EventPage = () => {
  const params = useParams();

  const { data, loading } = useQuery(EVENT, {
    variables: { where: { _id: params.id } },
    fetchPolicy: "network-only",
  });

  const navigate = useNavigate();

  const [deleteEvent, { loading: deleting }] = useMutation(DELETE_EVENT, {
    onCompleted: () => {
      navigate("/events");
      message.success("Мероприятие удалено");
    },
    onError: (err) => {
      console.error(err);
      message.error("Произошла ошибка, повторите еще раз");
    },
  });

  const event = data?.event;

  const handleDelete = () => {
    deleteEvent({ variables: { where: { _id: params.id } } });
  };

  if (!params.id) return null;

  return (
    <>
      <Top title="Мероприятие" />
      {loading ? (
        <Spin />
      ) : !event ? (
        <Empty />
      ) : (
        <>
          <Images>
            {event.images.map((item) => (
              <img key={item} src={`/uploads/${item}`} alt={item} />
            ))}
          </Images>
          <LabeledValue label="Название" value={event.name} />
          <LabeledValue label="Описание" value={event.description} />
          <LabeledValue
            label="Статус"
            value={
              event.published ? (
                <Tag color="green">Опубликован</Tag>
              ) : (
                <Tag color="red">Не опубликован</Tag>
              )
            }
          />
          <LabeledValue
            label="Дата создания"
            value={moment(event.createdAt).format("DD.MM.YYYY HH:mm")}
          />
          <Actions>
            <Link to={`/events/${params.id}/update`}>
              <Button type="primary" ghost>
                Изменить
              </Button>
            </Link>
            <Popconfirm title="Удалить?" onConfirm={handleDelete}>
              <Button loading={deleting} type="primary" danger ghost>
                Удалить
              </Button>
            </Popconfirm>
          </Actions>
        </>
      )}
    </>
  );
};

const Images = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  img {
    height: 150px;
    border: 1px solid #d8d8d8;
    margin-right: 15px;
    margin-bottom: 15px;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  button {
    margin-right: 15px;
  }
`;
