import React from "react";
import { Button, Form, Modal, message } from "antd";
import { useMutation } from "@apollo/client";

import { SmenaForm } from "./create-smena";
import { UPDATE_SMENA } from "../../gqls/smena";
import dayjs from "dayjs";
import { useUser } from "../../utils";
import styled from "styled-components";

export const UpdateSmena = ({
  refetch,
  smena,
  updateVisible = false,
  onClose,
  updatingDate,
}) => {
  const { user } = useUser();

  const [updateSmena, { loading }] = useMutation(UPDATE_SMENA, {
    onCompleted: () => {
      if (onClose) onClose();
      message.success("Смена изменена");
      refetch();
    },
    onError: (err) => {
      console.error(err);
      if (err.message === "in-smena") {
        message.error("Нельзя обновить текущую смену");
      } else if (err.message === "out-smena") {
        message.error("Нельзя обновить прошлую смену");
      } else {
        message.error("Произошла ошибка, повторите еще раз");
      }
    },
  });

  const handleFinishUpdate = (values) => {
    if (!smena) return;
    const data = { info: values.info };
    updateSmena({ variables: { where: { _id: smena._id }, data } });
  };

  return (
    <Modal
      title={
        <Title type={smena?.smenaType?.name}>
          {smena?.smenaType ? (
            <div className="type">{smena.smenaType.name}</div>
          ) : null}
          {updatingDate
            ? user.role === "admin"
              ? `Смена на ${dayjs(updatingDate).locale("ru").format("ll")}`
              : `Изменить смену на ${dayjs(updatingDate)
                  .locale("ru")
                  .format("ll")}`
            : null}
        </Title>
      }
      open={updateVisible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Отмена
        </Button>,
        user.role === "admin" ? null : (
          <Button
            key="update"
            type="primary"
            htmlType="submit"
            form={`update-smena-${smena?._id}`}
            loading={loading}
          >
            Изменить
          </Button>
        ),
      ]}
      destroyOnClose
    >
      <Form
        onFinish={handleFinishUpdate}
        layout="vertical"
        name={`update-smena-${smena?._id}`}
        initialValues={{ info: smena?.info }}
      >
        <SmenaForm disable={user.role === "admin"} />
      </Form>
    </Modal>
  );
};

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .type {
    margin-right: 5px;
    text-transform: uppercase;

    ${(props) => (props.type === "standart" ? "color: #0F583D;" : "")}

    ${(props) => (props.type === "vip" ? "color: #B8860B;" : "")}

    ${(props) => (props.type === "platinum" ? "color: #CCCCCC;" : "")}
  }
`;
