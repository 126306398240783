import { useState } from "react";
import styled from "styled-components";

const View = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  max-width: 600px;
  margin-bottom: 12px;
  min-height: 22px;

  .label {
    font-weight: 600;
    width: ${(props) => (props.column ? "100%" : "30%")};
    margin-right: 10px;
    line-height: 22px;
  }

  .value {
    width: ${(props) => (props.column ? "100%" : "70%")};
    white-space: pre-line;
    font-weight: 300;
    margin-top: ${(props) => (props.column ? `5px` : `0`)};
    line-height: 22px;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;

    .label {
      margin-right: 0;
      margin-bottom: 3px;
      width: 100%;
    }

    .value {
      width: 100%;
    }
  }
`;

const ShowMore = styled.span`
  color: #1677ff;
  cursor: pointer;
  margin-left: 5px;
`;

const LabeledValue = ({
  className,
  label,
  value,
  column,
  valueStyle,
  hasShowMore = false,
}) => {
  const [showMore, setShowMore] = useState(false);

  let textValue = value;

  if (!value) {
    if (value === 0) {
      textValue = "0";
    } else {
      textValue = "-";
    }
  }

  if (hasShowMore) {
    if (!showMore) {
      textValue = value.substring(0, 300) + "...";
    } else {
      textValue = value;
    }
  }

  return (
    <View className={className} column={column}>
      <div className="label">{label}</div>
      <div className="value" style={valueStyle}>
        {textValue}
        {hasShowMore && !showMore ? (
          <ShowMore onClick={() => setShowMore(true)}>Показать еще</ShowMore>
        ) : null}
      </div>
    </View>
  );
};

export default LabeledValue;
