import React, { useState } from "react";
import { Button, Form, Input, Switch, message } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";

import Top from "../../components/Top";
import { Upload } from "../../components/Upload";
import { EVENT, UPDATE_EVENT } from "../../gqls/event";

export const UpdateEventPage = () => {
  const params = useParams();

  const [files, setFiles] = useState([]);

  const [form] = Form.useForm();

  const { loading: disabled } = useQuery(EVENT, {
    variables: { where: { _id: params.id } },
    onCompleted: (data) => {
      const event = data?.event;
      if (event) {
        form.setFieldsValue({
          name: event.name,
          description: event.description,
          published: event.published,
        });
        if (Array.isArray(event.images)) {
          setFiles(
            event.images.map((item) => ({
              status: "done",
              url: `/uploads/${item}`,
              name: item,
              response: { filename: item },
              uid: item,
            }))
          );
        }
      }
    },
  });

  const navigate = useNavigate();

  const [updateEvent, { loading }] = useMutation(UPDATE_EVENT, {
    onCompleted: () => {
      message.success("Мероприятие изменено");
      navigate("/events/" + params.id);
    },
    onError: (err) => {
      console.error(err);
      message.error("Произошла ошибка, повторите еще раз");
    },
  });

  const handleFinish = (values) => {
    const images = files
      .map((item) => item?.response?.filename)
      .filter((item) => item);
    if (!images.length) {
      message.error("Загрузите хотя бы одно изображение");
      return;
    }
    const existUploading = files.filter((item) => item.status === "uploading");
    if (existUploading.length) {
      message.error("Дождитель загрузки изображений");
      return;
    }
    const existError = files.filter((item) => item.status === "error");
    if (existError.length) {
      message.error("Удалите изображения с ошибкой");
      return;
    }
    const data = {
      ...values,
      images,
    };
    updateEvent({ variables: { data, where: { _id: params.id } } });
  };

  return (
    <>
      <Top title="Изменить мероприятие" />
      <Form
        onFinish={handleFinish}
        name="update-event"
        layout="vertical"
        style={{ maxWidth: 500 }}
        form={form}
      >
        <Form.Item name="name" label="Название" rules={[{ required: true }]}>
          <Input disabled={disabled} />
        </Form.Item>
        <Form.Item
          name="description"
          label="Описание"
          rules={[{ required: true }]}
          disabled={disabled}
        >
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item label="Изображения" required>
          <Upload
            disabled={disabled}
            multiple
            fileList={files}
            setFileList={setFiles}
          />
        </Form.Item>
        <Form.Item name="published" label="Опубликован" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Button
          disabled={disabled}
          loading={loading}
          type="primary"
          htmlType="submit"
        >
          Изменить
        </Button>
      </Form>
    </>
  );
};
