import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { Link } from "react-router-dom";

import Top from "../../components/Top";
import { useUser } from "../../utils";
import { EVENTS } from "../../gqls/event";
import { Button, Table, Tag } from "antd";

const LIMIT = 60;

export const EventsPage = () => {
  const { user } = useUser();

  const [page, setPage] = useState(1);

  const placeId = user.role === "place" ? user.place._id : undefined;

  const { data: eventsData, loading } = useQuery(EVENTS, {
    variables: { data: { placeId, skip: (page - 1) * LIMIT, limit: LIMIT } },
    fetchPolicy: "network-only",
  });

  const events = eventsData?.events || [];
  const eventsCount = eventsData?.eventsCount || 0;

  const handleChangeTable = (pagination) => {
    setPage(pagination.current);
  };

  return (
    <>
      <Top
        title="Мероприятия"
        action={
          user.role === "place" ? (
            <Link to="/events/create">
              <Button type="primary" ghost>
                Добавить
              </Button>
            </Link>
          ) : undefined
        }
      />
      <Table
        dataSource={events}
        loading={loading}
        rowKey={(row) => row._id}
        pagination={{
          pageSize: LIMIT,
          current: page,
          total: eventsCount,
        }}
        onChange={handleChangeTable}
        columns={[
          {
            title: "Название",
            dataIndex: "name",
            key: "name",
            render: (name, row) => (
              <Link to={`/events/${row._id}`}>{name}</Link>
            ),
          },
          {
            title: "Статус",
            dataIndex: "published",
            key: "published",
            render: (published) =>
              published ? (
                <Tag color="green">Опубликован</Tag>
              ) : (
                <Tag color="red">Не опубликован</Tag>
              ),
          },
          {
            title: "Дата создания",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (createdAt) => moment(createdAt).format("DD.MM.YYYY HH:mm"),
          },
          user.role === "admin"
            ? {
                title: "Заведение",
                render: (row) => (
                  <Link to={`/places/${row.place._id}`}>{row.place.name}</Link>
                ),
              }
            : undefined,
        ].filter((item) => !!item)}
      />
    </>
  );
};
