import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: whitesmoke;
  }

  .center {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
