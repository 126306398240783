import { gql } from "@apollo/client";

const PLACES_FRAGMENT = gql`
  fragment PlaceReturn on Place {
    _id
    name
    image
    workStartAt
    workEndAt
    description
    category {
      _id
      name
    }
    categories {
      _id
      name
    }
    published
    createdAt
    contactPhone
    weekend
  }
`;

export const PLACES = gql`
  ${PLACES_FRAGMENT}
  query PLACES($data: PlacesInput) {
    places(data: $data) {
      ...PlaceReturn
    }

    placesCount(data: $data)
  }
`;

export const PLACE = gql`
  ${PLACES_FRAGMENT}
  query PLACE($where: PlaceWhereInput!) {
    place(where: $where) {
      ...PlaceReturn
    }
  }
`;

export const CREATE_PLACE = gql`
  ${PLACES_FRAGMENT}
  mutation CREATE_PLACE($data: CreatePlaceInput!) {
    createPlace(data: $data) {
      ...PlaceReturn
    }
  }
`;

export const UPDATE_PLACE = gql`
  ${PLACES_FRAGMENT}
  mutation UPDATE_PLACE($data: UpdatePlaceInput!, $where: PlaceWhereInput!) {
    updatePlace(data: $data, where: $where) {
      ...PlaceReturn
    }
  }
`;
