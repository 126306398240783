import { gql } from "@apollo/client";

export const CATEGORIES = gql`
  query CATEGORIES {
    categories {
      _id
      name
    }
  }
`;
