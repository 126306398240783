import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import Top from "../../components/Top";
import { Upload } from "../../components/Upload";
import { useUser } from "../../utils";
import { CREATE_EVENT } from "../../gqls/event";

export const CreateEventPage = () => {
  const { user } = useUser();

  const [files, setFiles] = useState([]);

  const navigate = useNavigate();

  const [createEvent, { loading }] = useMutation(CREATE_EVENT, {
    onCompleted: () => {
      message.success("Мероприятие добавлено");
      navigate("/events");
    },
    onError: (err) => {
      console.error(err);
      message.error("Произошла ошибка, повторите еще раз");
    },
  });

  const handleFinish = (values) => {
    const images = files
      .map((item) => item?.response?.filename)
      .filter((item) => item);
    if (!images.length) {
      message.error("Загрузите хотя бы одно изображение");
      return;
    }
    const existUploading = files.filter((item) => item.status === "uploading");
    if (existUploading.length) {
      message.error("Дождитель загрузки изображений");
      return;
    }
    const existError = files.filter((item) => item.status === "error");
    if (existError.length) {
      message.error("Удалите изображения с ошибкой");
      return;
    }
    const data = {
      ...values,
      placeId: user.place._id,
      images,
    };
    createEvent({ variables: { data } });
  };

  if (user.role === "admin") return null;
  if (!user?.place?._id) return null;

  return (
    <>
      <Top title="Добавить мероприятие" />
      <Form
        onFinish={handleFinish}
        name="create-event"
        layout="vertical"
        style={{ maxWidth: 500 }}
      >
        <Form.Item name="name" label="Название" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Описание"
          rules={[{ required: true }]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item label="Изображения" required>
          <Upload multiple fileList={files} setFileList={setFiles} />
        </Form.Item>
        <Button loading={loading} type="primary" htmlType="submit">
          Добавить
        </Button>
      </Form>
    </>
  );
};
